import React from 'react';

import {
  IoLogoTumblr,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoInstagram,
} from 'react-icons/io';

import styles from './index.module.css';

const Social = () => (
  <div className={styles.container}>
    <a href="https://atrayaulait.tumblr.com/" className={styles.tumblr}>
      <IoLogoTumblr />
    </a>
    <a
      href="https://www.linkedin.com/in/atrayee-sarkar-6939a2138"
      className={styles.linkedin}
    >
      <IoLogoLinkedin />
    </a>
    <a href="https://twitter.com/atrayaulait" className={styles.twitter}>
      <IoLogoTwitter />
    </a>
    <a href="https://www.instagram.com/amsarka/" className={styles.instagram}>
      <IoLogoInstagram />
    </a>
  </div>
);

export default Social;
