import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { renderToReact } from '../rich-text';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Social from '../components/social';

import styles from './category.module.css';

const CategoryPage = ({
  data: {
    category: { name, heading, projects },
  },
}) => {
  return (
    <Layout>
      <SEO title={name} />
      <div className={styles.heading}>
        {heading ? renderToReact(heading) : <h1>{name}</h1>}
      </div>
      <div className={styles.projects}>
        {projects.map(project => (
          <div className={styles.project} key={project.id}>
            <Link
              to={project.slug || project.fields.slug}
              className={styles.projectLink}
            >
              <div className={styles.projectImageContainer}>
                {project.poster && (
                  <Img
                    fluid={project.poster.fluid}
                    className={styles.projectImage}
                  />
                )}
              </div>
              <div className={styles.projectOverlay}>{project.name}</div>
            </Link>
          </div>
        ))}
      </div>
      <Social />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    category: contentfulProjectsPage(id: { eq: $id }) {
      name
      heading {
        json
        embeds {
          internal {
            type
          }
          ... on ContentfulAsset {
            fields {
              normalizedContentfulId
            }
            file {
              url
              contentType
            }
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
              aspectRatio
            }
          }
          ... on ContentfulLayout {
            fields {
              normalizedContentfulId
            }
            body {
              json
            }
            styles {
              fields {
                array {
                  key
                  value
                }
              }
            }
          }
          ... on ContentfulGalleryWithCaptionsWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              asset {
                file {
                  url
                  contentType
                }
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyContentfulFluid_tracedSVG
                  aspectRatio
                }
              }
              caption {
                json
              }
            }
          }
          ... on ContentfulGalleryWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              file {
                url
                contentType
              }
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
                aspectRatio
              }
            }
          }
          ... on ContentfulSideBySideWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              file {
                url
                contentType
              }
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
                aspectRatio
              }
            }
          }
        }
      }
      projects {
        ... on Node {
          ... on ContentfulProject {
            id
            name
            poster {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
            fields {
              slug
            }
          }
          ... on ContentfulProjectsPage {
            id
            name
            slug
            poster {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

export default CategoryPage;
